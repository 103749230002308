import React from "react"
import ContactForm from "../components/ContactForm"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image";
import Typewriter from 'typewriter-effect';

const contact = () => {
  return (
  <>
  <SEO title="Contact Us"/>
  <Layout>
    <section>
      <div className="hero-containerSubPage">
        <div className="heroTextOverlay">
          <center>
            <h1 className="heroText">Contact Information</h1>
            <h5 className="heroSubText">Phone: (412) 573-9831</h5>
            <h5 className="heroSubText">Email: info@arloktech.com</h5>
          </center>
        </div>
        <StaticImage
          alt="Contact Us"
          className="backgroundImage"
          style={{position: "absolute"}}
          src="../assets/ContactHeroImg.jpeg"
        />
    </div>
  </section>
    <ContactForm />
    <section className="bg-whiteSmoke">
  </section>
  </Layout>
  </>
  )
}

export default contact
